import React, { useContext, useState, useEffect } from 'react'
import { ContextLoader } from '../context'
import loadFonts from '../helpers/loadFonts'
import { adaptBgColorToVideo } from '../helpers/adaptBgColorToVideo'

const timeout = 1000;

const Loader = () => {

	const [isLoadingEnding, updateIsLoadingEnding] = useState(false)
	const [isLoading, updateIsLoading] = useContext(ContextLoader)

	// General loading handler
	useEffect(() => {
		setTimeout(() => {
			adaptBgColorToVideo();
		}, 500)
		// First load fonts
		handleFontLoad().then(() => {
			setTimeout(() => {
				updateIsLoadingEnding(true)
				setTimeout(() => {
					updateIsLoading(false)
				}, timeout * 0.25);
				setTimeout(() => {
					updateIsLoadingEnding(false)
				}, timeout)
			}, 500);
		})
	}, [updateIsLoading])

	// Load fonts
	const handleFontLoad = () => {
		return new Promise((resolve, reject) => {
			loadFonts().then(() => {
				resolve()
			});
		})
	};

	return (
		<>
			<div
				className={`Loader ${isLoadingEnding ? 'Loader--ending' : ''} ${!isLoading ? 'Loader--ended' : ''}`}
			>
				<div className="Loader__Sail"></div>
			</div>
		</>
	)
}

export default Loader