import React, { useState }  from 'react'
import Transition from './Transition'
import Loader from './Loader'
import './../styles/style.scss'
import { ContextLoader } from '../context';
import ColorWitnessImage from '../images/background-color-witness.png';

if (typeof window !== `undefined`) {
	// Set local storage is-touch
	if ("ontouchstart" in document.documentElement) {
		localStorage.setItem('is-touch', 'true');
		document.querySelector('html').classList.add('is-touch');
	} else {
		localStorage.setItem('is-touch', 'false');
	}

	// 100vh bug for phones
	window.onresize = function () {
		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		let vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
	window.onresize();

	// Force scroll to top on refresh
	window.onbeforeunload = function () {
		window.scrollTo(0, 0);
	}
}

const Layout = ({ children, location, navigation, footer }) => {

	const [isLoading, updateIsLoading] = useState(true);

	return (
		<>
			<ContextLoader.Provider value={[isLoading, updateIsLoading]}>
				<Loader />
				<img src={ColorWitnessImage} className="ColorWitnessImage" />
				<div className={`Global ${!isLoading ? 'Global--IsLoaded' : ''}`}>
					<Transition location={location}>
						{children}
					</Transition>
				</div>
			</ContextLoader.Provider>
		</>
	)
}

export default Layout